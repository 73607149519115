.container {
  padding: 10px;
  input[type="text"] {
    padding: 12px 20px;
    box-sizing: border-box;
    border: 1px solid #cfcfcf;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    @media only screen and (max-width: 390px) {
      width: 100%;
    }
    &:focus {
      border: 1px solid #000000;
    }
  }
  .footer-btn {
    padding: 14px;
    background-color: black;
    color: white;
    font-weight: 600;
    cursor: pointer;
    @media only screen and (max-width: 390px) {
      margin-top: 20px;
    }
  }
}
