.image-section {
  .mobile-images {
    .product-img {
      width: 110px !important;
      cursor: pointer;
    }
  }
  .main-product-img {
    max-width: 85% !important;
    @media (max-width: 320px) {
      width: 100% !important;
    }
  }
}
.input-position {
  div {
    img {
      max-width: none !important;
    }
  }
}
