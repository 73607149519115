.info-section {
  select {
    padding: 12px 20px;
    // margin-right: 8px;
    box-sizing: border-box;
    border: 1px solid #cfcfcf;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    &:focus {
      border: 1px solid #000000;
    }
  }
  .error-border {
    border: 1px solid red;
    &:focus {
      border: 1px solid red;
    }
  }
  input[type="number"] {
    padding: 6px 10px;
    // margin-right: 8px;
    // width: 50%;
    box-sizing: border-box;
    border: 1px solid #cfcfcf;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    &:focus {
      border: 1px solid #000000;
    }
  }
  .modal-btn {
    padding: 14px;
    background-color: black;
    color: white;
    font-weight: 600;
    cursor: pointer;
    margin-top: 20px;
    width: 100%;
  }
}
.description {
  @media (max-width: 420px) {
    font-size: 13px !important;
  }
}
.details {
  @media (max-width: 420px) {
    font-size: 13px !important;
  }
}

.sizeGuide {
  width: 35rem;
}
