.container {
  .img-container {
    .contact-img {
      max-height: 70%;
    }
  }
  .contact-form {
    .name-field {
      display: flex;
      justify-content: space-between;
      gap: 10px;
    }
  }
  .contact-btn {
    padding: 24px;
    background-color: #9f9f9f;
    color: white;
    font-weight: 600;
    cursor: pointer;
    margin-top: 20px;
    width: 50%;
  }
}
