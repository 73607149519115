.container {
  .checkout-form {
    width: 420px;
    .email-btn {
      padding: 10px 30px;
      background-color: #060606;
      color: white;
      font-weight: 600;
      cursor: pointer;
    }
    .delivery-methods {
      select {
        padding: 12px 20px;
        box-sizing: border-box;
        border: 1px solid #cfcfcf;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        outline: none;
        &:focus {
          border: 1px solid #000000;
        }
      }
    }
  }
  .order-summary {
    width: 480px;
    position: sticky;
    top: 2%;
    @media (max-width: 1024px) {
      position: relative !important;
    }
    .products-summary {
      .amount {
        .input-field {
          border: none;
          flex-grow: 1;
          width: 55px;
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
  &:before {
    content: "";
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    color: black;

    padding: 0 0.5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: black;
    background-color: #fcfcfa;
  }
}
