.container {
  .products {
    .right {
      flex: 0.25;
      &:ul {
        flex-direction: column;
      }
    }
    .left {
      // flex: 0.75;/
      .product-container {
        position: relative;
        // width: 150px;
        width: 100%;
        transition: all 0.9s ease-in-out;
        @media (max-width: 360px) {
          // width: 110px;
        }
        .image-container {
          overflow: hidden;
          .product-cover {
            // width: 200px;
            // height: 300px;
            transition: all 0.5s ease-in-out;
            @media only screen and (max-width: 643px) {
              // width: 380px;
            }
          }
        }
        .hover-button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 18px 10px;
          width: 70%;
          background-color: #000;
          color: white;
          border: none;
          cursor: pointer;
          opacity: 0;
          font-size: 12px;
          text-transform: uppercase;
          transition: all 0.5s ease-in-out;
        }
        &:hover {
          .hover-button {
            opacity: 1;
          }
          .product-cover {
            transform: scale(1.8);
          }
        }
      }
    }
  }
}

.stop-scrolling-modal {
  position: fixed;
  width: 100%;
}
.image-placeholder {
  width: 100%;
  height: 300px; /* Adjust this based on your desired placeholder height */
  background-color: #f0f0f0; /* Light gray background */
  position: relative;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.image-placeholder::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #000;
  animation: spin 1s linear infinite;
}
