.container {
  .cart-btn {
    border-radius: 50%;
    font-size: 25px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      box-shadow: inset 1px 2px 40px #9f9f9f;
    }
  }
  .cart-shopping-btn {
    padding: 10px;
    background-color: #000;
    color: white;
    font-weight: 600;
    cursor: pointer;
    margin-top: 10px;
    width: 70%;
  }
}
.flexCol {
  @media (max-width: 520px) {
    flex-direction: column !important;
  }
}
