.stop-scrolling {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}
nav {
  margin-top: -20px;
  @media (max-width: 350px) {
    font-size: 10px !important;
  }
}
