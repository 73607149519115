.container {
  .imgslider {
    width: 600px !important;
    margin: 5rem auto;
    @media only screen and (max-width: 768px) {
      width: 450px !important;
      margin: 8rem auto;
    }
    @media only screen and (max-width: 660px) {
      width: 400px !important;
      margin: 6rem auto;
    }
    @media only screen and (max-width: 570px) {
      width: 310px !important;
      margin: 4rem auto;
    }
    @media only screen and (max-width: 480px) {
      width: 280px !important;
      margin: 3rem auto;
    }
    @media only screen and (max-width: 380px) {
      width: 210px !important;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      position: relative;
    }
    .slick-arrow.slick-prev:before,
    .slick-arrow.slick-next:before {
      font-size: 40px;
      color: black;
      @media only screen and (max-width: 480px) {
        font-size: 20px;
      }
    }
    .slick-arrow.slick-prev {
      left: -10rem;
      @media only screen and (max-width: 1280px) {
        left: -9rem;
      }
      @media only screen and (max-width: 1024px) {
        left: -4rem;
      }
      @media only screen and (max-width: 480px) {
        left: -2rem;
      }
    }
    .slick-arrow.slick-next {
      right: -10rem;
      @media only screen and (max-width: 1280px) {
        right: -9rem;
      }
      @media only screen and (max-width: 1024px) {
        right: -3rem;
      }
      @media only screen and (max-width: 480px) {
        right: -2rem;
      }
    }
  }
}
